import { Component } from '@angular/core';

@Component({
  selector: 'sgm-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: [
    './statistic.component.scss'
  ]
})
export class StatisticComponent { }
