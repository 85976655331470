import { Component } from '@angular/core';

@Component({
  selector: 'sgm-statistic-label',
  templateUrl: './statistic-label.component.html',
  styleUrls: [
    './statistic-label.component.scss'
  ]
})
export class StatisticLabelComponent { }
