<ng-container *ngIf="accompaniment.important">
  <div class="alert alert-primary mb-6 mb-md-8">
    <i class="fas fa-exclamation-circle"></i> Este acompañamiento ha sido marcado como importante.
  </div>
</ng-container>

<h4>Problemas Encontrados:</h4>

<div class="mb-6 mb-md-8 text-muted">
  <!-- academic -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.academic">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Académicos</p>
  </div>

  <!-- administrative -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.administrative">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Administrativos</p>
  </div>

  <!-- economic -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.economic">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Económicos</p>
  </div>

  <!-- psychosocial -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.psychosocial">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Psicosociales</p>
  </div>

  <!-- connectivity -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.connectivity">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Conectividad</p>
  </div>

  <!-- other -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.other">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Otros problemas</p>
  </div>

  <!-- none -->
  <div class="d-flex align-items-center" *ngIf="accompaniment.problems.none">
    <div class="badge badge-lg badge-success mr-4">+</div>
    <p class="mb-0">Ningún problema</p>
  </div>
</div>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.problems.otherDescription">
  <h3>Otro Tema</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.problems.otherDescription | titlecase }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.topicDescription">
  <h3>Temas Desarrollados</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.topicDescription }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.subproblem">
  <h3>Subgrupo del Problema</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.subproblem }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.problematic">
  <h3>Problemática</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.problematic }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.problemDescription">
  <h3>Descripción del Problema</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.problemDescription }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

<ng-container *ngIf="accompaniment.solutionDescription">
  <h3>Soluciones</h3>
  <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
    <span>{{ accompaniment.solutionDescription }}</span>
  </p>
</ng-container>

<!--  -->
<!--  -->
<!--  -->

  <ng-container *ngIf="!!accompaniment.confirmation && isAdmin">
    <h3>Se recibio el acompañamiento</h3>
    <p class="mb-6 mb-md-8 text-muted">
      <span *ngIf="accompaniment.confirmation.isGiven">Sí</span>
      <span *ngIf="!accompaniment.confirmation.isGiven">No</span>
    </p>


    <!--  -->
    <!--  -->
    <!--  -->
    <ng-container *ngIf="accompaniment.confirmation.isGiven">
    <h3>Valoración del estudiante nuevo</h3>
    <p class="mb-6 mb-md-8 text-muted">
      {{ accompaniment.confirmation.qualification | qualificationValue }}
    </p>

    <!--  -->
    <!--  -->
    <!--  -->

    <ng-container *ngIf="!!accompaniment.confirmation.comment">
      <h3>Sugerencias y Comentarios</h3>
      <p class="mb-6 mb-md-8 text-muted" style="white-space:pre-wrap;">
        <span>{{ accompaniment.confirmation.comment }}</span>
      </p>
    </ng-container>

    <!--  -->
    <!--  -->
    <!--  -->

  </ng-container>

    <h3>Firma del Estudiante</h3>
    <img class="img-fluid border-bottom" [src]="accompaniment.confirmation.digitalSignature" alt="Firma Digital" />
    <p class="mb-6 mb-md-8 text-muted">
      <small>
        <b>Firma</b>: {{ accompaniment.student.displayName | titlecase }}
      </small>
    </p>

</ng-container>

<!--  -->
<!--  -->
<!--  -->
<ng-container *ngIf="!!accompaniment.assets && accompaniment.assets.length > 0">
  <h3>Anexos</h3>
  <div class="mb-6 mb-md-8">
    <ng-container *ngFor="let asset of accompaniment.assets">
      <div class="d-flex align-items-center ">
        <div class="badge badge-lg badge-success mr-4">+</div>
        <a [href]="asset.downloadUrl" target="_blank" rel="noopener noreferrer">
          {{ asset.name }}
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>
