import { Component } from '@angular/core';

@Component({
  selector: 'sgm-statistic-percentage',
  templateUrl: './statistic-percentage.component.html',
  styleUrls: [
    './statistic-percentage.component.scss'
  ]
})
export class StatisticPercentageComponent { }
