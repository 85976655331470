<div class="statistic-card">
  <!-- label position -->
  <div class="statistic-card-label">
    <ng-content select="sgm-statistic-label"></ng-content>
  </div>
  <!-- value position -->
  <div class="statistic-card-value">
    <ng-content select="sgm-statistic-value"></ng-content>
  </div>
  <!-- percentage position -->
  <div class="statistic-card-percentage">
    <ng-content select="sgm-statistic-percentage"></ng-content>
  </div>
</div>
